import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./jocker.scss";
import { AuthContext } from "../../context/AuthContext";

import grilled from "../../img/flavor/grilled.png";
import cajun from "../../img/flavor/cajun.png";
import green from "../../img/flavor/green.png";
import feta from "../../img/flavor/feta.png";
import harvest from "../../img/flavor/harvest.png";
import mango from "../../img/flavor/mango.png";
import tomato from "../../img/flavor/tomato.png";
import honey from "../../img/flavor/honey.png";
import Pickled_Onion_Punch from "../../img/flavor/Pickled_Onion_Punch.png";
import Five_Spice_Fusion from "../../img/flavor/Five_Spice_Fusion.png";
import Truffle_Treasure from "../../img/flavor/Truffle_Treasure.png";
import Zesty_Zaatar from "../../img/flavor/Zesty_Zaatar.png";
import Crunchy_Pickled_Cucumber from "../../img/flavor/Crunchy_Pickled_Cucumber.png";
import Blue_Cheese_Blast from "../../img/flavor/Blue_Cheese_Blast.png";
import Classic_BBQ from "../../img/flavor/Classic_BBQ.png";
import Wasabi_Whirlwind from "../../img/flavor/Wasabi_Whirlwind.png";
import Smoky_BBQ_Sensation from "../../img/flavor/Smoky_BBQ_Sensation.png";
import Ranch_Rush from "../../img/flavor/Ranch_Rush.png";
import black from "../../img/flavor/black.png";
import Sweet_Paprika_Pop from "../../img/flavor/Sweet_Paprika_Pop.png";
import masala from "../../img/flavor/masala.png";
import Kimchi_Kick from "../../img/flavor/Kimchi_Kick.png";
import jocker from "../../img/jkr.png";
import Magic_Mushroom from "../../img/flavor/Magic_Mushroom.png";
import { BsPersonCircle } from "react-icons/bs";
import winImg from "../../img/WEB_in.png";
import axios from "axios";
import loose from "../../img/jkrh.png";

const Jocker = () => {
  const { qrCode } = useParams();
  const [clickedId, setClickedId] = useState("");
  const [chancesLeft, setChancesLeft] = useState(3);
  const [gameMessage, setGameMessage] = useState("");
  const [show, setShow] = useState(false);
  const [winGameMessage, setWinGameMessage] = useState("");
  const [isGameOver, setIsGameOver] = useState(false);
  const [hiddenFlavors, setHiddenFlavors] = useState([]);
  const [shuffledFlavors, setShuffledFlavors] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const userName = user?.details?.name;
  const email = user?.details?.email;
  const [isActive, setIsActive] = useState(false);
  const [selectFlavors, setSelectFlavors] = useState(false);
  const [flavorId, setFlavorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [error, setError] = useState("");
  const [isCodeChecked, setIsCodeChecked] = useState(false);
  const [isCodeUsed, setIsCodeUsed] = useState(false);

  // const apiLink = "http://localhost:4348/api/v1";
  const apiLink = "https://xl-api.notionspromos.com/api/v1";

  const promotion_name = "jocker";

  //  useEffect(() => {
  //    // This effect runs on component load
  //    navigate("/");
  //  }, [navigate]);



  const coupon = qrCode;

  const handleTouchStart = () => {
    setIsActive(true);
  };

  const handleTouchEnd = () => {
    setIsActive(false);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    if (
      touch.clientX < rect.left ||
      touch.clientX > rect.right ||
      touch.clientY < rect.top ||
      touch.clientY > rect.bottom
    ) {
      setIsActive(false);
    }
  };

  // useEffect(() => {
  //   if (!qrCode) {
  //     return;
  //   }

  //   const getVarifyCode = async () => {
  //     setLoading(true);
  //     try {
  //       await axios.get(`${apiLink}/getUsedCode/${qrCode}`).then((response) => {
  //         const isCodeValid = response.data.qr;
  //         setApiResponse(isCodeValid);
  //       });
  //     } catch (error) {
  //       setError("Code not valid or Already Used");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   getVarifyCode();
  // }, [qrCode]);

  // const localStoreCode = localStorage.getItem("coupon");

  // console.log("apiResponse",apiResponse);
  //  console.log("localStoreCode", localStoreCode);

  useEffect(() => {
    if (!clickedId) {
      return;
    } else {
      const checkCode = async () => {
        try {
          await axios
            .get(`${apiLink}/jocker/${coupon}/${clickedId}`)
            .then((response) => {
              setSelectFlavors(response.data.message);
              // console.log(response.data.message);
            });
        } catch (error) {}
      };
      checkCode();
    }
  }, [clickedId]);

  useEffect(() => {
    if (!coupon) {
      return;
    }
    const moveCodeToUseCode = async () => {
      try {
        await axios.get(`${apiLink}/move_ues_code/${coupon}`).then(response=>{
          setIsCodeUsed(response.data)
        });
      } catch (error) {
        console.log(error);
      }
    };
    moveCodeToUseCode();
  }, [coupon]);
  

  const flavors = [
    {
      id: "grilled",
      value: selectFlavors,
      img: grilled,
    },
    {
      id: "cajun",
      value: selectFlavors,
      img: cajun,
    },

    {
      id: "green",
      value: selectFlavors,
      img: green,
    },
    {
      id: "feta",
      value: selectFlavors,
      img: feta,
    },
    {
      id: "Pickled_Onion",
      value: selectFlavors,
      img: Pickled_Onion_Punch,
    },
    {
      id: "harvest",
      value: selectFlavors,
      img: harvest,
    },
    {
      id: "mango",
      value: selectFlavors,
      img: mango,
    },
    {
      id: "tomato",
      value: selectFlavors,
      img: tomato,
    },

    {
      id: "honey",
      value: selectFlavors,
      img: honey,
    },
    {
      id: "Five_Spice_Fusion",
      value: selectFlavors,
      img: Five_Spice_Fusion,
    },
    {
      id: "Truffle",
      value: selectFlavors,
      img: Truffle_Treasure,
    },
    {
      id: "Zaatar",
      value: selectFlavors,
      img: Zesty_Zaatar,
    },
    {
      id: "Crunchy_Pickled_Cucumber",
      value: selectFlavors,
      img: Crunchy_Pickled_Cucumber,
    },
    {
      id: "Blue_Cheese",
      value: selectFlavors,
      img: Blue_Cheese_Blast,
    },
    {
      id: "Classic_BBQ",
      value: selectFlavors,
      img: Classic_BBQ,
    },
    {
      id: "Wasabi",
      value: selectFlavors,
      img: Wasabi_Whirlwind,
    },
    {
      id: "Smoky_BBQ_Sensation",
      value: selectFlavors,
      img: Smoky_BBQ_Sensation,
    },
    {
      id: "Ranch_Rush",
      value: selectFlavors,
      img: Ranch_Rush,
    },
    {
      id: "black",
      value: selectFlavors,
      img: black,
    },
    {
      id: "Sweet_Paprika_Pop",
      value: selectFlavors,
      img: Sweet_Paprika_Pop,
    },
    {
      id: "masala",
      value: selectFlavors,
      img: masala,
    },
    {
      id: "Kimchi_Kick",
      value: selectFlavors,
      img: Kimchi_Kick,
    },
    {
      id: "Magic_Mushroom",
      value: selectFlavors,
      img: Magic_Mushroom,
    },
  ];

  // const [correctFlavor,setCorrectFlavor] = useState("")

  const correctFlavor = flavors.find((flavor) => flavor.value === true);

  useEffect(() => {
    if (!clickedId) {
      return;
    }
    navigate(`/wheel/${coupon}/${promotion_name}`, { replace: true });
  }, [correctFlavor]);

  // Initialize chances from localStorage on first load
  useEffect(() => {
    const savedChances = parseInt(localStorage.getItem("chancesLeft"), 10);
    if (savedChances && savedChances <= 3) {
      setChancesLeft(savedChances);
      if (savedChances === 0) {
        setGameMessage("Sorry, you've run out of chances");
        setIsGameOver(true);
        // navigate('/')
      }
    }
  }, []);

  // Function to handle click events and update chances
  const handleClick = (id) => {
    if (isGameOver) return;
    setClickedId(id);

    if (chancesLeft > 0) {
      if (correctFlavor?.value === true) {
        return;
      } else {
        const updatedChances = chancesLeft - 1;
        setChancesLeft(updatedChances);
        setHiddenFlavors((prev) => [...prev, id]);

        // Update localStorage each time a chance is used
        if(updatedChances === 0){
          localStorage.setItem("chancesLeft", "gameLost");
        }

        if (updatedChances === 0) {
          setGameMessage("Sorry, you've run out of chances");
          setIsGameOver(true);
        } else {
          setGameMessage(
            `Wrong guess. You have ${updatedChances} chances left.`
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!isGameOver) {
      return;
    }

    navigate("/gameover");
  }, [isGameOver]);

  // Function to reset the game if needed (can be called with a button)
  const resetGame = () => {
    setChancesLeft(3);
    setHiddenFlavors([]);
    setIsGameOver(false);
    setGameMessage("");
    localStorage.removeItem("chancesLeft");
  };

  // Function to shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle flavors on component mount
  useEffect(() => {
    setShuffledFlavors(shuffleArray([...flavors]));
  }, []);

  const gameLost = localStorage.getItem("chancesLeft");

  // useEffect(() => {
  //   if (localStoreCode === apiResponse) {
  //     setIsCodeUsed(true);
  //   }
  //   setIsCodeChecked(true);
  // }, []); // empty dependency array ensures this runs only on mount

  // if (!isCodeChecked) {
  //   return null; // Render nothing until the check is complete
  // }

  return (
    <div
      className="__max_w"
      // style={{
      //   backgroundImage: `url(${jocker})`,
      //   // backgroundColor: "#dce400",
      //   backgroundSize: "cover",
      //   backgroundPosition: "top",
      //   objectFit: "contain",
      // }}
    >
      {show && (
        <div
          className="jocker_win"
          style={{
            backgroundImage: `url(${winImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "contain",
          }}
        >
          <div
          // className="win_msg_wrp"
          // style={{
          //   backgroundImage: `url(${jocker})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   objectFit: "contain",
          // }}
          >
            <Link
              to={(`/wheel/${coupon}/${promotion_name}`, { replace: true })}
            >
              <button>SPIN THE WHEEL</button>
            </Link>
          </div>
        </div>
      )}

      {isGameOver !== show && (
        <div
          className="jocker_win"
          // style={{
          //   backgroundImage: `url(${winImg})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   objectFit: "contain",
          // }}
        >
          <p style={{ color: "white", textAlign: "center" }}>
            Better Luck Next Time Register your details to keep you updated of
            our future promotions
          </p>
          <div
            className="win_msg_wrp"
            // style={{
            //   backgroundImage: `url(${loose})`,
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   objectFit: "contain",
            // }}
          ></div>
          <div className="img_wrp">
            <img src={`${loose}`} alt="" />
          </div>

          <div className="info_msg">
            <Link to={"/"}>
              <button> Back to Home </button>
            </Link>
          </div>
        </div>
      )}

      {gameLost ? (
        <div div className="__min_w">
          <div className="nice_try">
            <div>
              <h2>Nice Try 😁</h2>
            </div>
            <br />
            <Link to={"/"}>
              <div>
                <button button className="btn">
                  Back to Home
                </button>
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div
            className="__min_w"
            // style={{
            //   backgroundImage: `url(${jocker})`,
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   objectFit: "contain",
            // }}
          >
            <div className="jocker">
              <div className="j_head">
                <img src={require("../../img/bner.png")} alt="" />
              </div>
              <div className="jocker_head">
                <div>
                  <div className="jocker_head_num">
                    <p>
                      <div className="_jocker_head_pro">
                        <span className="_span">{chancesLeft}</span>
                      </div>
                      {/* Chances left */}
                    </p>
                  </div>
                </div>
                <div className="game_status">
                  <p>
                    <em>
                      {!gameMessage ? (
                        <>
                          {" "}
                          <p>
                            Guess the flavor you just tasted from the menu
                            below, and get the chance to win a prize. <br />{" "}
                            <em>You have 3 Chances Starting Now!</em>
                          </p>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <p>{gameMessage}</p>
                        </>
                      )}
                    </em>
                  </p>
                </div>
              </div>

              <div className="card_wrp">
                {shuffledFlavors.map((flavor) => (
                  <div
                    key={flavor.id}
                    className={`_f_card ${isActive ? "active" : ""} ${
                      hiddenFlavors.includes(flavor.id) ? "disabled" : ""
                    }`}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onTouchMove={handleTouchMove}
                    id={flavor.id}
                    onClick={() => handleClick(flavor.id)}
                  >
                    <img src={flavor.img} alt="" />
                  </div>
                ))}
              </div>
              {/* <div className="note">
            <p>
              <em>Note:</em> Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Labore, ratione pariatur. Dolores, quae? Debitis quos
              perspiciatis numquam sunt consectetur error voluptates deserunt,
              ipsum, modi explicabo praesentium adipisci et. Nostrum sint
              molestias maiores ex. Debitis harum iusto voluptas libero quis
              repudiandae. Numquam velit temporibus voluptates! Nobis, quam et
              in expedita natus autem dolor aut esse numquam, nulla
              exercitationem sequi. Voluptates saepe error iure ducimus! Ab
              temporibus magnam ducimus error suscipit, cumque voluptatibus odit
              tenetur, nobis ipsam, est illum. Culpa consectetur doloribus
              quidem incidunt consequuntur quos qui sapiente, ex nemo hic
              consequatur possimus repellendus architecto quam saepe quasi
              quibusdam aliquam vel ipsa?
            </p>
          </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Jocker;
