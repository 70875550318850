import React from 'react'
import "./gameOver.scss";
import { Link } from 'react-router-dom';
import loose from "../../img/jkrh.png";

const GaneOver = () => {
  return (
    <div
      className="jocker_win"
      // style={{
      //   backgroundImage: `url(${winImg})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   objectFit: "contain",
      // }}
    >
      {/* <p style={{ color: "white", textAlign: "center" }}>{gameMessage}</p> */}
      <div
        className="win_msg_wrp"
        // style={{
        //   backgroundImage: `url(${loose})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   objectFit: "contain",
        // }}
      ></div>

      <div className="img_wrp">
        <p style={{ color: "white", textAlign: "center" }}>
          Better Luck Next Time <br /> Register your details to keep you updated
          of our future promotions
        </p>
        <img src={`${loose}`} alt="" />
      </div>

      <div className="info_msg">
        <Link to={"/guest-register"}>
          <button> Register </button>
        </Link>
      </div>
    </div>
  );
}

export default GaneOver
