import React, { useState } from 'react'
import "./looserRegister.scss";
import countryList from "./countryList.js";
import axios from 'axios';

const LooserRegister = () => {

    const [birthDay, setBirthDay] = useState("");
    const [residence, setResidence] = useState("");
    const [nationality, setNationality] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [loading,setLoading] = useState(false)
      const [completeRequest, setCompleteRequest] = useState("");


    const devApiLink = "https://xl-api.notionspromos.com/api/v1";

   const handleSaveLooser = async (e) => {
     e.preventDefault();
     setLoading(true);
     const userData = {
       userEmail: email,
       userName: name,
       phone,
       birthDay,
       residence,
       nationality,
     };
     try {
       const response = await axios.post(`${devApiLink}/save-looser`, userData);
       setCompleteRequest(response.status === 200);
       if (response.status === 200) {
         window.location.href = "https://www.notionsgroup.com/sub_snack_brands";
       }
     } catch (error) {
       console.log(error);
     } finally {
       setLoading(false);
     }
   };



  return (
    <div className="__fw">
      <div className="__mw">
        <div className="loos_reg_wrp">
          <div className="loos_reg_wrp_logo">
            <img src={require("../../img/xl.webp")} alt="" />
          </div>

          <form className="_form" onSubmit={handleSaveLooser}>
            <div>
              <label className="__label"> Full Name</label>
              <input
                type="text"
                required
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="__label"> Email</label>
              <input
                type="email"
                required
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="__label"> Phone</label>
              <input
                type="tel"
                required
                placeholder="Phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label className="__label"> Residence</label>
              <select
                required
                value={residence}
                onChange={(e) => setResidence(e.target.value)}
              >
                <option value="">Select</option>
                {countryList.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="__label"> Nationality</label>
              <select
                required
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
              >
                <option value="">Select</option>
                {countryList.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="__label"> Date of Birth</label>
              <input
                style={{ width: "100%" }}
                required
                type="date"
                value={birthDay}
                onChange={(e) => setBirthDay(e.target.value)}
              />
            </div>

            <button type="Register" disabled={loading}>
              {loading ? "Registering..." : "Register Now"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LooserRegister
